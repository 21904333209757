<template>
<v-container fluid fill-height v-if="show">
    <v-card :class="`align-self-stretch ` + ($vuetify.breakpoint.mdAndUp ? `pa-4 ` : `pa-0` )" :flat="$vuetify.breakpoint.mdAndUp ? true : false" :elevation="$vuetify.breakpoint.mdAndUp ? `1` : `0`" width="100%">
        <v-card-title class="px-0">
            <h1>Магазини</h1>
        </v-card-title>
        <!-- <pre>{{list[3]}}</pre> -->
        <v-data-table class="my-4" hide-default-footer multi-sort :loading="loading" :headers="filteredHeaders" disable-pagination :items="list">
            <template v-slot:item.actions="{ item }">
                <v-btn icon title="Редагувати" @click="editCard(item)" v-if="$vuetify.breakpoint.smAndDown" :disabled="!contactInfo.realUserId ? false : true">
                    <v-icon class="green--text">mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon title="Підтвердити актуальність данних" :disabled="item.format && item.area && !contactInfo.realUserId ? false : true" @click="confirmCard(item)">
                    <v-icon class="link--text pl-1">mdi-hand-okay</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.edit="{ item }">
                <v-btn icon title="Редагувати" @click="editCard(item)" v-if="$vuetify.breakpoint.mdAndUp" :disabled="!contactInfo.realUserId ? false : true">
                    <v-icon class="green--text">mdi-pencil</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.address="{ item }">
                {{item.city_name}}, {{item.address}}
            </template>
            <template v-slot:item.photo="{ item }">
                <v-icon color="indigo accent-3" small v-if="item.photo">mdi-image-multiple-outline</v-icon>
            </template>
            <template v-slot:item.is_f5="{ item }">
                <span v-if="item.is_f5" class="primary--text">Категорія: {{item.categoryName}}</span>
            </template>
        </v-data-table>
        <v-card-actions>
            <v-btn :disabled="!contactInfo.realUserId ? false : true" @click="newShop()">ДОДАТИ МАГАЗИН</v-btn>
        </v-card-actions>
    </v-card>
    <v-dialog v-model="editDialog" persistent width="700" :fullscreen="$vuetify.breakpoint.mdAndUp ? false : true">
        <v-system-bar dark>
            <v-spacer></v-spacer>
            <v-icon @click="editDialog=false">mdi-close</v-icon>
        </v-system-bar>
        <v-card tile>
            <v-card-title class="headline">
                Карточка Магазина
            </v-card-title>
            <v-card-text class="text-right font-weight-bold">
                <div v-if="f.shop_id">Номер: {{f.shop_id}}</div>
            </v-card-text>
            <v-card-text>
                <!-- {{f}}  -->
                <v-text-field label="Вивіска" outlined v-model="f.shop_name"></v-text-field>
                <v-subheader class="text-subtitle-2">Адреса магазина:</v-subheader>
                <v-autocomplete v-model="f.oblast_id" outlined :items="regions" @change="getCities()" label="Область"></v-autocomplete>
                <v-subheader class="mt-n8 pl-0 text-subtitle-2">Місто:</v-subheader>
                <div class="d-flex">
                    <v-checkbox @click="setCity('id')" v-model="cityDef['id']"></v-checkbox>
                    <v-autocomplete v-model="f.city_id" outlined :disabled="cityDef['text']" auto-select-first  :filter="customFilter" :items="cities" label="Виберіть зі списку"></v-autocomplete>
                </div>
                <div class="d-flex">
                    <v-checkbox @click="setCity('text')" v-model="cityDef['text']"></v-checkbox>
                    <v-text-field label="Інше" outlined :disabled="cityDef['id']" v-model="f.cityTxt"></v-text-field>
                </div>
                <v-subheader class="mt-n8 pl-0 text-subtitle-2">Вулиця:</v-subheader>
                <div class="d-flex">
                    <v-checkbox @click="setStreet('id')" v-model="streetDef['id']"></v-checkbox>
                    <v-autocomplete v-model="f.street_id" outlined auto-select-first :disabled="streetDef['text']" :search-input.sync="search" :items="streets" label="Виберіть зі списку" no-data-text="Введіть назву вулиці"></v-autocomplete>
                </div>
                <div class="d-flex">
                    <v-checkbox @click="setStreet('text')" v-model="streetDef['text']"></v-checkbox>
                    <v-text-field label="Інше" outlined :disabled="streetDef['id']" v-model="f.streetTxt"></v-text-field>
                </div>
                <v-text-field label="Будинок" outlined v-model="f.building"></v-text-field>
                <v-subheader class="text-subtitle-2">Данні про магазин:</v-subheader>
                <v-autocomplete v-model="f.format_id" outlined auto-select-first :items="formats" label="Формат"></v-autocomplete>
                <v-text-field label="Торг. площа, м2" outlined v-model="f.area"></v-text-field>
                <v-textarea rows="3" outlined append-icon="mdi-briefcase" auto-grow label="Режим роботи" v-model="f.worktime"></v-textarea>
                <v-subheader class="text-subtitle-2">Проект «F5»:</v-subheader>
                <v-switch v-model="f.is_f5" readonly persistent-hint :label="(f.is_f5 ? 'Включена у Проект «F5»' : 'Ні' )"></v-switch>
                <v-text-field v-if="f.is_f5" label="Категорія F5" readonly v-model="f.categoryName"></v-text-field>
                <div v-if="f.mscCode">
                    <v-subheader class="text-subtitle-2">MCS 2.0:</v-subheader>
                    <v-text-field label="Код" readonly v-model="f.mscCode"></v-text-field>
                    <v-text-field label="Статус" readonly v-model="statuses[f.mscStatus]"></v-text-field>
                </div>
                <v-subheader class="text-subtitle-2">Фото магазина:</v-subheader>
                <div v-if="f.photo">
                    <v-badge color="indigo accent-3" offset-y="100%" offset-x="30" icon="mdi-image-outline" v-for="(v, key) in f.photo.split(',')" :key="key">
                        <a target="_blank" :href="`https://intranet.yugcontract.ua${v}`" class="mr-4 mb-4">
                            <v-img class="d-inline-block" width="50" :src="`https://intranet.yugcontract.ua${v}`"></v-img>
                        </a>
                    </v-badge>
                </div>
                <div v-else>
                    <h4 class="text-center">Фото магазина не маємо.</h4>
                </div>
                <v-subheader class="text-subtitle-2 mt-4">Примітки до магазину:</v-subheader>
                <v-textarea rows="3" append-icon="mdi-comment" auto-grow outlined label="Ваш коментар" v-model="f.comment"></v-textarea>

            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" text @click="sendShopsData()">
                    Надiслати
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="editDialog=false">
                    Закрити
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDialog" max-width="600">
        <v-card>
            <v-card-title class="headline">
                Підтвердження
            </v-card-title>

            <v-card-text>
                <p>Ви підтверджуєте, що вся інформація про Магазин 100% актуальна.</p>
                <p>Продовжити?</p>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="confirmShopData()">
                    Так
                </v-btn>
                <v-btn text @click="confirmDialog = false">
                    Нi
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import {
    mapActions
} from 'vuex'

export default {
    components: {},
    data: () => ({
        show: false,
        editDialog: false,
        confirmDialog: false,
        confirmData: {},
        cityDef: {},
        streetDef: {},
        f: {},
        search: '',
        statuses: {
            0: 'не зареєстрована',
            1: 'активна',
            2: 'закрита',
        },

        headers: [{
                text: '',
                align: 'left',
                sortable: false,
                value: 'actions',
                width: '2%',
                selected: true,
            },
            {
                text: 'Номер ТТ',
                align: 'center',
                sortable: true,
                value: 'shop_id',
                width: '10%',
                selected: true,
            },
            {
                text: 'Фото',
                align: 'center',
                value: 'photo',
                selected: true,
                width: '5%',
            },
            {
                text: 'Вивіска',
                align: 'center',
                value: 'shop_name',
                selected: true,
                width: '15%',
            },
            {
                text: 'Область',
                align: 'center',
                value: 'region',
                selected: true,
                width: '10%',
            },
            {
                text: 'Адреса',
                align: 'center',
                value: 'address',
                selected: true,
                width: '15%',
            },
            {
                text: 'Формат',
                align: 'center',
                value: 'format',
                selected: true,
                width: '10%',
            },
            {
                text: 'Торг. площа, м2',
                align: 'center',
                value: 'area',
                width: '10%',
                selected: true,
            },
            {
                text: 'F5',
                align: 'center',
                value: 'is_f5',
                width: '10%',
                selected: true,
            },
            {
                text: '',
                align: 'left',
                sortable: false,
                value: 'edit',
                width: '2%',
                selected: true,
            },
        ],

    }),
    props: {},
    watch: {
        search(val) {
            val && val !== this.select && this.querySelections(val)
        },
    },

    methods: {
        ...mapActions(['touch', 'getContactInfo', 'setSnackbar', 'getShopsList', 'getCitiesAutocomplete',
            'getRegionsAutocomplete', 'getStreetsAutocomplete', 'getShopsFormats', 'sendInfoShopsData', 'confirmShop'
        ]),
        editCard(item) {
            this.editDialog = true
            this.f = item
            this.$store.commit('setStreets', [])
            this.cityDef['id'] = true
            this.streetDef['id'] = true

            // for (let el in item) {
            //     this.f[el] = item[el]
            // }
            this.getCitiesAutocomplete(this.f.oblast_id)
                .then(() => {
                    this.getStreetsAutocomplete({
                            cityId: this.f.city_id,
                            streetId: this.f.street_id,
                        })
                        .then(() => {
                            this.overlay = false
                        })
                })

        },
        confirmCard(item) {
            this.confirmDialog = true
            for (let el in item) {
                this.confirmData[el] = item[el]
            }
        },
        confirmShopData() {
            this.touch()
                .then(() => {
                    this.confirmShop(this.confirmData)
                        .then(data => {
                            this.getShopsList()
                            this.setSnackbar(['success', 'Магазин пiдтверджено'])
                        })
                    this.confirmDialog = false

                })
                .catch(error => console.error(error))
        },
        newShop() {
            this.editDialog = true
            this.f = {}
            this.$store.commit('setStreets', [])
            this.cityDef['id'] = true
            this.streetDef['id'] = true
        },
        getCities() {
            this.overlay = true
            this.getCitiesAutocomplete(this.f.oblast_id)
                .then(() => {
                    this.overlay = false
                })
        },
        querySelections(v) {
            this.overlay = true
            this.getStreetsAutocomplete({
                    cityId: this.f.city_id,
                    keyword: v
                })
                .then(() => {
                    this.overlay = false
                })
        },
        setCity(v) {
            if (v == 'text') {
                this.cityDef['id'] = false
                this.$set(this.f, 'city_id', 0)
            } else {
                this.cityDef['text'] = false
                this.$set(this.f, 'cityTxt', '')
            }
        },
        setStreet(v) {
            if (v == 'text') {
                this.streetDef['id'] = false
                this.$set(this.f, 'street_id', '0')
            } else {
                this.streetDef['text'] = false
                this.$set(this.f, 'streetTxt', '')

            }
        },
        sendShopsData() {
            this.touch()
                .then(() => {
                    this.sendInfoShopsData(this.f)
                    this.setSnackbar(['success', 'Дякуємо. Повідомлення про нові дані про Магазин надіслано модератору.'])
                    this.editDialog = false
                })
                .catch(error => console.error(error))
        },
        customFilter(item, queryText, itemText) {
            const textOne = item.text.toLowerCase()
            const textTwo = item.name.toLowerCase()
            const searchText = queryText.toLowerCase()

            return textOne.indexOf(searchText) > -1 ||
                textTwo.indexOf(searchText) > -1
        },

    },
    created() {
        this.getContactInfo()
            .then(data => {
                if (data.groups && data.groups.cl_shops && !data.isSellToEmployee) {
                    this.$vuetify.goTo(0)
                    this.show = true
                    this.getShopsList()
                    this.getShopsFormats()
                    this.getRegionsAutocomplete()
                } else {
                    this.$router.push('/403')
                }
            })
            .catch(error => console.log(error))
    },
    computed: {
        contactInfo() {
            return this.$store.state.main.contactInfo
        },
        list() {
            return this.$store.state.catalog.shopsList
        },
        loading() {
            return this.$store.state.main.loading
        },
        regions() {
            return this.$store.state.order.regions
        },
        cities() {
            return this.$store.state.order.cities
        },
        streets() {
            return this.$store.state.order.streets
        },
        formats() {
            return this.$store.state.catalog.shopsFormats
        },
        filteredHeaders() {
            return this.headers.filter(h => {
                return h.selected && !h.hidden
            })
        },

    }
}
</script>
