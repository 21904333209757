<template>
    <ShopsPage />
</template>

<script>
import ShopsPage from '../components/ShopsPage.vue'

export default {
    data: () => ({
    }),
    components: {
       ShopsPage
    },
    methods: {
    },
    mounted() {
    }
}
</script>
